const enviromentConfig = {
    development: {
        endPoint: 'https://gateway.dev.meu-solutions.com/ecommerce/'
    },
    production: {
        endPoint: 'https://ecommerce.meu-solutions.com/'
    },
    empty: {
        endPoint: 'https://ecommerce.meu-solutions.com/ecommercetwo'
    },
};
export const baseEndpoint = 'https://gateway.dev.meu-solutions.com/ecommerce';

export default enviromentConfig;
